import { GamesQueryKeys } from '../../queryKeys';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchGamesRecentlyPlayed } from './service';
import { Game } from '@/models/game';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import {
  UseGameRecentlyPlayedProps,
  UseGamesRecentlyPlayedQueryKeyProps,
} from './props';
import { useMemo } from 'react';
import { PaginatedResponse } from '@starsoft/common/models';
import { AuthService } from '@starsoft/common/services';
import { useInfiniteQueryCallbacks, useToggle } from '@starsoft/common/hooks';

export function useGamesRecentlyPlayed(options?: UseGameRecentlyPlayedProps) {
  const { refetchOnMount = true } = options ?? {};
  const [hideRecentGame, toggleHideRecentGame] = useToggle(false);

  const queryKey: UseGamesRecentlyPlayedQueryKeyProps = [
    GamesQueryKeys.RecentlyPlayed,
    {
      limit: 20,
    },
  ];

  const { getNextPageParam, getPreviousPageParam } =
    useInfiniteQueryCallbacks();

  const { data, isPending, error, refetch, fetchStatus, isError, ...rest } =
    useInfiniteQuery<
      PaginatedResponse<Game>,
      AxiosError<GenericError>,
      InfiniteData<PaginatedResponse<Game>>,
      UseGamesRecentlyPlayedQueryKeyProps
    >({
      queryKey,
      queryFn: fetchGamesRecentlyPlayed,
      enabled: AuthService.isAuthorized(),
      staleTime: 5 * 1000,
      refetchOnMount,
      refetchOnWindowFocus: false,
      getNextPageParam,
      getPreviousPageParam,
      initialPageParam: 1,
    });

  const games = useMemo(() => data?.pages?.flatMap(page => page?.data), [data]);

  const mostRecentGame = useMemo(() => games?.[0], [games]);

  return {
    games,
    isLoading: isPending && fetchStatus !== 'idle',
    error,
    isError,
    refetch,
    mostRecentGame,
    hideRecentGame,
    toggleHideRecentGame,
    metadata: data?.pages?.[0]?.metadata,
    ...rest,
  };
}
