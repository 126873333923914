import { QueryFunctionContext } from '@tanstack/react-query';
import baseAPI from '@/api';
import { Game } from '@/models/game';
import { UseGamesRecentlyPlayedQueryKeyProps } from './props';
import { PaginatedResponse } from '@starsoft/common/models';

export async function fetchGamesRecentlyPlayed({
  queryKey,
  pageParam,
}: QueryFunctionContext<UseGamesRecentlyPlayedQueryKeyProps>) {
  const { limit } = queryKey[1];

  const response = await baseAPI.get<PaginatedResponse<Game>>(
    '/games/recently-played',
    {
      params: {
        limit,
        page: pageParam,
      },
    },
  );

  return response.data;
}
