import { QueryFunctionContext } from '@tanstack/react-query';
import { GamesRequestDto } from '@/services/dto/games/games-request.dto';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { Game } from '@/models/game';
import baseAPI from '@/api';
import { UseGamesQueryKey } from './props';
import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { GameProviderId } from '@/enums/gameProviderId';

export async function fetchGames({
  pageParam,
  queryKey,
}: QueryFunctionContext<UseGamesQueryKey>): Promise<PaginatedResponse<Game>> {
  const { query, limit, tag, providerIds }: GamesRequestDto = queryKey[1];
  const isOriginals = tag === GameTagFilter.Originals;
  const mappedProviders = providerIds?.join(',');

  const response = await baseAPI.get<PaginatedResponse<Game>>('/games', {
    params: {
      page: pageParam,
      query,
      limit,
      providerIds: isOriginals
        ? GameProviderId.Originals
        : !!mappedProviders
          ? mappedProviders
          : null,
      tag: tag === GameTagFilter.Originals ? null : tag,
    },
  });

  return response.data;
}
