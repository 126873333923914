import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchUnifiedBets } from './service';
import { UnifiedBetsQueryKeys } from '../../queryKeys';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { usePaginatedQuery } from '@starsoft/common/hooks';
import { UnifiedBet } from '@/models/bet/unified';
import { UseUnifiedBetsProps, UseUnifiedBetsQueryKey } from './props';
import { UnifiedBetRequestDto } from '@/services/dto/unified-bets/unified-bet-request.dto';
import { BetType } from '@/models/bet/type';

export function useUnifiedBets(options?: UseUnifiedBetsProps) {
  const [page, setPage] = useState(1);
  const {
    order = 'DESC',
    sortBy = 'id',
    type = BetType.Casino,
    refetchInterval,
    endDate,
    startDate,
    limit = 50,
    refetchOnWindowFocus = true,
    gameId,
  } = options ?? {};

  const filter: UnifiedBetRequestDto = {
    page,
    limit,
    order,
    sortBy,
    endDate,
    startDate,
    type,
    gameId: gameId ?? null,
  };

  const queryKey: UseUnifiedBetsQueryKey = [
    UnifiedBetsQueryKeys.Primary,
    filter,
  ];

  const { data, isPending, error, refetch, isFetching } = useQuery<
    PaginatedResponse<UnifiedBet>,
    AxiosError<GenericError>,
    PaginatedResponse<UnifiedBet>,
    UseUnifiedBetsQueryKey
  >({
    queryKey,
    queryFn: fetchUnifiedBets,
    refetchInterval,
    staleTime: 5 * 1000,
    refetchOnWindowFocus,
  });

  const { fetchNextPage, fetchPreviousPage, setCurrentPage } =
    usePaginatedQuery({
      page,
      setPage,
      data,
      queryParams: {
        startDate,
        endDate,
        type,
        sortBy,
        order,
      },
    });

  return {
    bets: data,
    page,
    error,
    refetch,
    fetchNextPage,
    setCurrentPage,
    fetchPreviousPage,
    isFetching,
    isLoading: isPending,
  };
}
